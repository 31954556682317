
import { computed, defineComponent, ref, reactive } from "vue";
import Validations from '@/core/services/etc/Validations'
import { common } from '@/store/stateless/common'
import moment from "moment";
// import { hideModal } from "@/core/helpers/dom";
// import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
// import { array } from "yup/lib/locale";
import InvoiceService from "@/core/services/car/InvoiceService";
import { notif } from "@/store/stateless/store";
interface IUploadForm {
  invoice_id: any;
  invoice_doc: any;
}
// interface IResponse {
//   data: any;
//   message: string;
//   success: boolean;
// }
export default defineComponent({
  name: "upload-invoice-document-modal",
  components: {},

  setup(props,context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadInvoiceDocumentModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let displaySuccessMsg = ref<boolean>(false);
    // let response = ref<IResponse>({
    //   data: "",
    //   message: "",
    //   success: false,
    // });
    // const formData = ref({});
    const store = useStore();
    const ruleFormNameRef = ref()
    const reference_no = ref()

    const route = useRoute();
    const router = useRouter();
    let uploadFormData = ref<IUploadForm>({
      invoice_id: "",
      invoice_doc: "",
    });
    let file: any;

    const handleFileUpload = (event) => {
      Validations.is_doc_limit = false
      // formData = new FormData();
      uploadFormData.value.invoice_doc = event.target.files[0];
      receipt.value.invoice_doc = event.target.files[0];

      if((event.target.files[0].size  / 1024 / 1024) > 2)
        Validations.is_doc_limit = true
      //
    };
    const invoice_id = computed(() => {
      return store.getters.getInvoiceId;
    });

    const uploadInvoice = ref();
    const uploadInvoiceDocumet = (formEl) => {
      // console.log("sent");
      let formData = new FormData();

      for(const key in formEl.model) {
        if(key == 'payment_date') {
          formData.set(key, common.setDBDate(receipt.value[key]))
        } else {
          formData.set(key, receipt.value[key])
        }
      }

      formEl.validate(async(valid, fields) => {
            loading.value = true
            if(valid) {
                const response = await store.dispatch('addInvoiceDocuments', formData)
                
                if(response.status < 299) {
                    notif.simple("Approved Invoice", "success", "Your receipt has been uploaded successfully!");
                    closeModal()
                } else {
                  loading.value = false
                  if(response.response.data.data.reference_no) {
                    Validations.is_reference_no_exist = true
                    formEl.validateField('reference_no')
                    context.emit("callInvoiceList");
                    reference_no.value.focus()
                    
                  }
                }
                loading.value = false
            } else {
                loading.value = false
            }
        })

      // if(uploadFormData.value.invoice_doc) {
      //   Promise.all([InvoiceService.addInvoiceDocument(formData)]).then(
      //     (data) => {
      //       // console.log(data[0]);
      //       // console.log(response.success);
      //       displaySuccessMsg.value = true;
      //       uploadFormData.value = {
      //         invoice_id: invoice_id,
      //         invoice_doc: "",
      //       };
      //       uploadInvoice.value.reset();
      //       uploadInvoiceDocumentModalRef.value = null
      //       notif.simple("Approved Invoice", "success", "Your receipt has been uploaded successfully!");
      //       // setTimeout(() => {
      //       //   displaySuccessMsg.value = false
      //       // }, 5000);
      //       context.emit("callInvoiceList");
      //       closeModal();
            
      //       formData = new FormData();
      //     }
      //   );
      // }

    };
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    const isOpen = computed(() => {
      return store.getters.getOpenUploadInvoice;
    });
    const closeModal = () => {
      ruleFormNameRef.value.resetFields()
      Validations.is_reference_no_exist = false
      uploadInvoice.value.reset();
      InvoiceService.setModalInvoice(false);

      // uploadFormData.value.invoice_doc = null
    };

    const error = computed(() => {
      return store.getters.getUploadReceiptError
    })

    const receipt = computed(() => {
      return store.state.InvoiceModule.uploadReceipt
    })

    const rules = reactive({
        invoice_doc: [{ validator: Validations.invoice_doc, trigger: ['blur', 'change'] }],
        order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
        reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
        payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],
    })

    const resetError = () => {
      Validations.is_reference_no_exist = false
    }
    return {
      moment,
      resetError,
      ruleFormNameRef,
      rules,
      receipt,
      uploadFormData,
      error,
      invoice_id,
      closeModal,
      isOpen,
      loading,
      handleFileUpload,
      uploadInvoiceDocumet,
      disableSaveButton,
      displaySuccessMsg,
      uploadInvoiceDocumentModalRef,
      uploadInvoice,
    };
  },
});
